import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Meta, SortAndFilter } from '../types/general';
import { QuoteItem, QuoteResponse } from '../types/quote';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  constructor(private http: HttpBase) {}

  getQoutes(params: Meta, sortingAndFiltering?: SortAndFilter): Observable<QuoteResponse> {
    let path = '';
    if (sortingAndFiltering) {
      const param = `?filter=${sortingAndFiltering.filter}&sortBy=${sortingAndFiltering.sortBy}&order=${sortingAndFiltering.sortOrder}&page=${params.page}&take=${params.take}`;
      path = `quotes${param}`;
    } else {
      path = `quotes`;
    }

    return this.http.get(path);
  }

  getSingleQuote(id: number): Observable<QuoteItem> {
    return this.http.get(`quotes/${id}`);
  }

  getNextId(currentId: number): Observable<number> {
    return this.http.get(`quotes/next/${currentId}`);
  }

  getPrevId(currentId: number): Observable<number> {
    return this.http.get(`quotes/prev/${currentId}`);
  }

  updateQuote(values: any, id: number) {
    return this.http.put(`quotes/${id}`, values);
  }

  newQuote(values: any) {
    return this.http.post(`quotes`, values);
  }

  deleteQuote(id: number) {
    return this.http.delete(`quotes/${id}`);
  }
}
