import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { LoginResponse } from '../types/auth';
import { HttpBase } from '../utils/http-base';

export const JWT_TOKEN_LS_KEY = 'jwt_token';
export const USERNAME_LS_KEY = 'username';

export enum UserRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public jwtHelper: JwtHelperService,
    private router: Router,
    private http: HttpBase,
  ) {}

  public login(username: string, password: string): Observable<LoginResponse> {
    return this.http.post('auth/login', { username, password });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public register(username: string, password: string, role: UserRoles): Observable<any> {
    return this.http.post('auth/register', { username, password, role });
  }

  public logout(): void {
    // Remove token from local storage
    localStorage.removeItem(JWT_TOKEN_LS_KEY);
    this.router.navigate(['login'], { queryParams: { logoutSuccessfull: true } });
  }

  public getToken(): string | null {
    // Retrieve token from local storage
    return localStorage.getItem(JWT_TOKEN_LS_KEY);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    return !this.jwtHelper.isTokenExpired(token);
  }
}
