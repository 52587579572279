import { FormControl, FormGroup } from '@angular/forms';

import { convertCamelCaseToTitleCase } from './utils';

// export function markFormControlsAsDirty(form: FormGroup) {
//   Object.keys(form.controls).forEach(key => {
//     markControlDirty(<FormControl>form.get(key));
//   });
// }

export function markFormControlsAsDirty(form: FormGroup, parentKey: string = ''): string[] {
  const invalidFields: string[] = [];

  Object.keys(form.controls).forEach(key => {
    const control = form.get(key);
    const fullKey = parentKey ? `${parentKey} - ${convertCamelCaseToTitleCase(key)}` : convertCamelCaseToTitleCase(key); // Create full field path

    if (control instanceof FormControl) {
      control.markAsDirty({ onlySelf: true });
      if (control.invalid) {
        invalidFields.push(fullKey); // Add full field path if invalid
      }
    } else if (control instanceof FormGroup) {
      // Recursively handle nested FormGroup, passing down the parent key
      const childInvalidFields = markFormControlsAsDirty(control, fullKey);
      invalidFields.push(...childInvalidFields);
    }
  });

  return invalidFields;
}

export function markControlDirty(formControl: FormControl) {
  formControl.markAsDirty();
}

export function checkIfFormIsModified(form: FormGroup): boolean {
  if (form.status === 'VALID') {
    console.warn("it's the same");
    return false;
  } else {
    console.warn("it's different");
    return true;
  }
}
