<!-- <div class="header p-4">
  <p-menubar [model]="items">
    <ng-template pTemplate="start">
      <img
        src="../../../assets/logo/favicon-holztreppen-aus-polen.png"
        height="40"
        class="mr-2"
        alt="Holztreppenauspolen favicon"
        srcset=""
      />
    </ng-template>
    <ng-template pTemplate="end">
      <div class="flex align-items-center">
        <span class="mr-2 username">{{ username }}</span>
        <p-button
          icon="pi pi-power-off"
          severity="secondary"
          label="Logout"
          (click)="logout()"
        ></p-button>
      </div>
    </ng-template>
  </p-menubar>
</div> -->
<div class="header sm:p-4 p-0">
  <p-menubar [model]="items">
    <ng-template pTemplate="start">
      <img
        src="../../../assets/logo/favicon-holztreppen-aus-polen.png"
        height="40"
        class="main-logo mr-2"
        alt="Holztreppenauspolen favicon"
        (click)="goToDashboard()"
      />
    </ng-template>
    <ng-template pTemplate="end">
      <div class="flex align-items-center">
        <span class="mr-2 username hidden sm:inline">{{ username }}</span>
        <p-button
          icon="pi pi-power-off"
          severity="secondary"
          [label]="isDesktop ? 'Ausloggen' : ''"
          (click)="logout()"
          styleClass="p-button-sm"
        ></p-button>
      </div>
    </ng-template>
  </p-menubar>
</div>