import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [],
  templateUrl: './form-error.component.html',
  styleUrl: './form-error.component.less',
})
export class FormErrorComponent {
  @Input() public type!: 'pattern' | 'empty' | 'invalid';
  @Input() public fieldName!: string;
}
