import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Observable } from 'rxjs/internal/Observable';
import { QuoteService } from 'src/app/services/quote.service';
import { Meta, SortAndFilter } from 'src/app/types/general';

import { QuoteItem, QuoteResponse } from '../../../types/quote';

@Component({
  selector: 'app-quote-list',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ButtonModule,
    TableModule,
    IconFieldModule,
    InputTextModule,
    InputIconModule,
    RouterModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './quote-list.component.html',
  styleUrl: './quote-list.component.less',
})
export class QuoteListComponent {
  public quotes: QuoteItem[] = [];
  public firstQuoteIds: { [email: string]: number } = {};
  public filteredQuotes: QuoteItem[] = [];
  public dataAndMeta$: Observable<QuoteResponse>;
  public quotesMeta: Meta = <Meta>{
    page: 1,
    take: 100,
    itemCount: 0,
    pageCount: 1,
    hasPreviousPage: false,
    hasNextPage: true,
  };
  loading: boolean = true;

  public expandedRows: { [key: string]: boolean } = {};
  public sortingAndFiltering: SortAndFilter;

  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
  ) {}

  // Lazy loaded event from p-table
  public loadQuotes(event: TableLazyLoadEvent) {
    this.loading = true;

    const selectedPage = Math.floor(<number>event.first / <number>event.rows) + 1;
    this.sortingAndFiltering = {
      sortBy: event.sortField ? event.sortField : 'createdAt',
      sortOrder: event.sortOrder === 1 ? 'DESC' : 'ASC',
      filter: event.globalFilter ? event.globalFilter : '',
    };

    this.quotesMeta = {
      take: <number>event!.rows,
      page: selectedPage,
      itemCount: this.quotesMeta.itemCount,
      pageCount: 1,
      hasPreviousPage: this.quotesMeta.hasPreviousPage,
      hasNextPage: this.quotesMeta.hasNextPage,
    };

    this.getQuotes(this.sortingAndFiltering);
  }

  public clearSearch(table: Table) {
    table.clear();
  }

  public getQuotes(sortingAndFiltering?: SortAndFilter) {
    this.loading = true;
    this.quoteService.getQoutes(this.quotesMeta, sortingAndFiltering).subscribe(res => {
      // Step 1: Group quotes by email
      const groupedQuotes = res.data.reduce(
        (acc, quote) => {
          if (!acc[quote.email]) {
            acc[quote.email] = [];
          }
          acc[quote.email].push(quote);
          return acc;
        },
        <{ [key: string]: any[] }>{},
      );

      // Step 2: Sort each group by createdAt in descending order (newest quotes first within each group)
      Object.values(groupedQuotes).forEach(group => {
        group.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      });

      // Step 3: Convert the grouped object back to an array, sorted by the newest quote in each group
      this.quotes = Object.values(groupedQuotes)
        .sort((a, b) => new Date(b[0].createdAt).getTime() - new Date(a[0].createdAt).getTime())
        .flat();

      this.quotesMeta = res.meta;
      this.loading = false;
    });
  }

  public editQuote(id: number) {
    this.router.navigate(['../edit-quote', id], { relativeTo: this.activatedRoute });
  }

  public showDeleteConfirmDialog(quote: QuoteItem) {
    this.confirmationService.confirm({
      message: `Do you want to delete this quote? <br><br>
      id: ${quote.id} <br>
      name: ${quote.name}`,
      header: 'Delete confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'pi pi-trash',
      rejectIcon: 'none',

      accept: () => {
        this.removeQuote(quote.id);
      },
      reject: () => {
        this.showNotDeletedToast();
      },
    });
  }

  public removeQuote(id: number) {
    this.quoteService.deleteQuote(id).subscribe({
      next: () => {
        this.showDeletedToast();
        this.getQuotes(this.sortingAndFiltering);
      },
      error: () => {
        this.showNotDeletedToast();
      },
    });
  }

  public initializeFirstAndRemainingQuotes() {
    const uniqueEmails = new Set<string>();

    this.filteredQuotes = this.quotes.filter(quote => {
      if (!uniqueEmails.has(quote.email)) {
        // Mark the first occurrence of each email
        this.firstQuoteIds[quote.email] = quote.id;
        uniqueEmails.add(quote.email);
        return false; // Exclude the first quote from filteredQuotes
      }
      return true; // Include subsequent quotes for display in rowexpansion
    });
  }

  public getFirstQuoteByEmail(email: string) {
    return this.quotes.find(quote => quote.email === email);
  }

  public getRemainingQuotesByEmail(email: string) {
    return this.filteredQuotes.filter(quote => quote.email === email);
  }

  public hasMultipleQuotes(email: string): boolean {
    return this.quotes.filter(quote => quote.email === email).length > 1;
  }

  // public expandAll() {
  //   this.expandedRows = this.quotes.reduce<{ [key: string]: boolean }>((acc, quote) => {
  //     acc[String(quote.id)] = true;
  //     return acc;
  //   }, {});
  // }

  public expandAll() {
    this.expandedRows = this.quotes.reduce<{ [key: string]: boolean }>((acc, quote) => {
      // Check if the group has more than one item by counting quotes with the same email
      const groupSize = this.quotes.filter(q => q.email === quote.email).length;
      if (groupSize > 1) {
        acc[quote.email] = true; // Only expand groups with more than one item
      }
      return acc;
    }, {});
  }

  public collapseAll() {
    this.expandedRows = {};
  }

  private showDeletedToast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Deleted',
      detail: 'Quote deleted',
    });
  }

  private showNotDeletedToast() {
    this.messageService.add({
      severity: 'error',
      summary: 'Rejected',
      detail: 'Quote NOT deleted',
    });
  }
}
