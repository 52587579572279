/* eslint-disable max-lines */
import { CommonModule, NgFor } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { Subject, takeUntil } from 'rxjs';
import { EmailService } from 'src/app/services/email.service';
import { ExtraElementsService } from 'src/app/services/extra-elements.service';
import { QuoteService } from 'src/app/services/quote.service';
import { UrlHelperService } from 'src/app/services/url-helper.service';
import { ExtraElements, quoteEdited, QuoteItem, quoteSent } from 'src/app/types/quote';
import { markFormControlsAsDirty } from 'src/app/utils/forms';
import {
  showSaveSuccess,
  showSaveError,
  showSendSuccess,
  showSendError,
  showRequiredFieldsError,
  showExtraElementSuccess,
  showExtraElementError,
  showExtraElementDeleteError,
  showExtraElementDeleteSuccess,
  showEmailEmptyError,
} from 'src/app/utils/toasts';
import { environment } from 'src/environments/environment';

interface DropdownType {
  name: string;
  value: string;
}

interface DropdownTypeNumber {
  name: string;
  value: number;
}

// export enum StairType {
//   HOLZWANGENTREPPE = 'Holzwangentreppe',
//   STAHLWANGENTREPPE = 'Stahlwangentreppe',
// }

@Component({
  selector: 'app-preise-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    FormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextareaModule,
    TabViewModule,
    SplitButtonModule,
    NgFor,
    CommonModule,
  ],
  providers: [ConfirmationService, UrlHelperService],
  templateUrl: './preise-edit.component.html',
  styleUrl: './preise-edit.component.less',
})
export class PreiseEditComponent implements OnInit, OnDestroy {
  public preiseForm: FormGroup;

  public quote: QuoteItem = <QuoteItem>{};
  public pdfUrl: SafeResourceUrl;

  public isAddMode: boolean = false;
  public showCeilingCasingLength: boolean;
  public isTax: boolean;

  public salutationOptions: DropdownType[];
  public stairTypes: DropdownType[];
  public stairWoodTypes: DropdownType[];
  public stairForms: DropdownType[];
  public woodColors: DropdownType[];
  public railingTypes: DropdownType[];
  public stairHeights: DropdownTypeNumber[] = [];
  public stairAmount: DropdownTypeNumber[] = [];

  public lastQuoteSent: quoteSent | null;
  public lastQuoteEdited: quoteEdited | null;

  public activeTabIndex: number = 0; // Start with the first tab

  public extraElementsMenuItems: MenuItem[];
  public extraElementsVisible: boolean;
  public extraElementsItems: ExtraElements[];
  private destroy$ = new Subject<void>();
  private validatorsChangeList = ['stairWoodType', 'stairForm', 'railingType'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private quoteService: QuoteService,
    private emailService: EmailService,
    private extraElementsService: ExtraElementsService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public confirmationService: ConfirmationService,
    public urlHelperService: UrlHelperService,
    public sanitizer: DomSanitizer,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  ngOnInit() {
    this.initExtraElementsMenuItems();
    this.route.params.subscribe((params: Params) => {
      const quoteId = params['id'];
      this.isAddMode = !quoteId;
      if (!this.isAddMode) {
        this.quoteService.getSingleQuote(+quoteId).subscribe(quote => {
          this.quote = quote;
          this.buildForm(this.quote);
          this.getLastSentEdited(this.quote);
          setTimeout(() => {
            this.getPdf(quote.pdfFileName);
          }, 200);
        });
      } else {
        this.buildForm();
      }
    });

    this.stairTypes = [
      { name: 'Holzwangentreppe', value: 'holzwangentreppe' },
      { name: 'Stahlwangentreppe', value: 'stahlwangentreppe' },
      { name: 'Mittelholmtreppe', value: 'mittelholmtreppe' },
      { name: 'Faltwerktreppe', value: 'faltwerktreppe' },
      { name: 'Faltwerkoptiktreppe', value: 'faltwerkoptiktreppe' },
      { name: 'Kragarmtreppe', value: 'kragarmtreppe' },
      { name: 'Beton', value: 'beton' },
      { name: 'None', value: 'none' },
    ];

    this.stairWoodTypes = [
      { name: 'Kiefer', value: 'kiefer' },
      { name: 'Buche', value: 'buche' },
      { name: 'Ahorn', value: 'ahorn' },
      { name: 'Esche', value: 'esche' },
      { name: 'Eiche', value: 'eiche' },
      { name: 'Kirsche', value: 'kirsche' },
    ];

    this.stairForms = [
      { name: 'Gerade', value: 'gerade' },
      { name: '1/4 gewendelt', value: '1/4' },
      { name: '2/4 gewendelt', value: '2/4' },
      { name: '1/2 gewendelt', value: '1/2' },
      { name: '1/2 gewendelt mit Podest', value: '1/2 mit podest' },
      { name: 'Spindeltreppe', value: 'spindel' },
    ];

    this.woodColors = [
      { name: 'Holz', value: 'holz' },
      { name: 'Anthrazit', value: 'anthrazit' },
      { name: 'Weiss', value: 'weiss' },
    ];

    this.railingTypes = [
      { name: 'Ohne Geländer', value: 'ohne gelander' },
      { name: 'Standard Holz', value: 'standard holz' },
      { name: 'Stahlstäbe', value: 'stahlstabe' },
      { name: 'Glas mit Holz', value: 'glas mit holz' },
      { name: 'Glas mit Edelstahl', value: 'glas mit edelstahl' },
      { name: 'Sicherheitsglas seitlich befestigt', value: 'sicherheitsglas seitlich befestigt' },
      { name: 'Glas mit Stahlprofil', value: 'glas mit stahlprofil' },
      { name: 'Sicherheitsglas eingelassen', value: 'sicherheitsglas eingelassen' },
      { name: 'Relinggeländer', value: 'relinggelander' },
      { name: 'Vollholzgeländer', value: 'vollholzgelander' },
    ];

    this.salutationOptions = [
      { name: 'Herr', value: 'herr' },
      { name: 'Frau', value: 'frau' },
    ];

    for (let i = 1; i <= 22; i++) {
      this.stairHeights.push({ name: `${i}`, value: i });
    }
    for (let i = 1; i <= 100; i++) {
      this.stairAmount.push({ name: `${i}`, value: i });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public buildForm(quote?: QuoteItem) {
    if (quote) {
      const extraElements: ExtraElements[] = quote.extraElements;

      this.preiseForm = this.formBuilder.group({
        id: [quote.id, [Validators.required]],
        offerNumber: [quote.offerNumber, [Validators.required]],
        client: this.formBuilder.group({
          id: [quote.client.id],
        }),
        salutation: [quote.salutation],
        name: [quote.name],
        surname: [quote.surname],
        email: [quote.email, [Validators.email]],
        postalCode: [quote.postalCode, [Validators.required]],
        phoneNumber: [quote.phoneNumber],
        installationDate: [new Date(quote.installationDate), [Validators.required]],
        ip: [quote.ipAddress, [Validators.required]],
        stairType: [quote.stairType, [Validators.required]],
        stairWoodType: [quote.stairWoodType, [Validators.required]],
        stairForm: [quote.stairForm, [Validators.required]],
        stairHeight: [quote.stairHeight, [Validators.required]],
        stairAmount: [quote.stairAmount, [Validators.required]],
        railingType: [quote.railingType, [Validators.required]],
        railingLength: [quote.railingLength],
        ceilingCasing: [quote.ceilingCasing],
        ceilingCasingLength: [quote.ceilingCasingLength],
        reisers: [quote.reisers],
        woodColor: [quote.woodColor, [Validators.required]],
        extraInfo: [quote.extraInfo],
        remarks: [quote.remarks],
        extraElements: this.formBuilder.array([]),
        discountPercentage: [quote.discountPercentage],
        addToPrice: [quote.addToPrice],
        tax: [quote.tax],
        taxPercentage: [quote.taxPercentage],
        qngCertificate: [quote.qngCertificate],
      });

      extraElements.forEach(element => this.addExtraElement(element));

      // this.preiseForm.get('extraInfo')?.disable();
    } else {
      this.preiseForm = this.formBuilder.group({
        id: ['', [Validators.required]],
        offerNumber: ['', [Validators.required]],
        client: this.formBuilder.group({
          id: [],
        }),
        salutation: [''],
        name: [''],
        surname: [''],
        email: [''],
        postalCode: [''],
        phoneNumber: [''],
        installationDate: [new Date(), [Validators.required]],
        ip: ['', [Validators.required]],
        stairType: ['', [Validators.required]],
        stairWoodType: ['', [Validators.required]],
        stairForm: ['', [Validators.required]],
        stairHeight: [15, [Validators.required]],
        stairAmount: [1, [Validators.required]],
        railingType: ['', [Validators.required]],
        railingLength: [],
        ceilingCasing: [false],
        ceilingCasingLength: [],
        reisers: [false],
        woodColor: ['holz', [Validators.required]],
        extraInfo: [''],
        remarks: [''],
        extraElements: this.formBuilder.array([]),
        discountPercentage: [''],
        addToPrice: [''],
        tax: [false],
        taxPercentage: [0],
        qngCertificate: [false],
      });
    }

    this.ceilingCasingChanges();
    this.taxChanges();
    this.typeOfWoodChanges();
    this.typeOfStairsChanges();

    this.preiseForm.get('id')?.disable();
    this.preiseForm.get('offerNumber')?.disable();
    this.preiseForm.get('ip')?.disable();
    if (quote?.stairType === 'none') {
      this.preiseForm.get('addToPrice')?.disable();
      this.primaryOptionsNotRequired(this.validatorsChangeList);
    }
  }

  get extraElements() {
    return <FormArray>this.preiseForm.get('extraElements');
  }

  public addExtraElement(element?: ExtraElements, close: boolean = false) {
    const elementForm = this.formBuilder.group({
      name: [element?.name || '', Validators.required],
      value: [element?.value || 0, [Validators.required, Validators.min(0)]],
      quantity: [element?.quantity || 0, [Validators.required, Validators.min(0)]],
      quantityUnit: [element?.quantityUnit || '', Validators.required],
      addToDatabase: [false],
      isEditing: [false],
    });
    this.extraElements.push(elementForm);

    if (close) {
      this.closeExtraElementsDialog();
    }
  }

  public addExistingExtraElement(showDialog = true) {
    this.extraElementsService
      .getAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: ExtraElements[]) => {
          this.extraElementsItems = data;
          if (showDialog) {
            this.showExtraElementsDialog();
          }
        },
      });
  }

  removeExtraElement(index: number) {
    this.extraElements.removeAt(index);
  }

  // eslint-disable-next-line max-lines-per-function
  public onSaveClick(sendEmailAfterSave: boolean): void {
    if (this.preiseForm.valid) {
      const values = this.preiseForm.value;
      // make integers
      values.railingLength = +values.railingLength;
      values.ceilingCasingLength = +values.ceilingCasingLength;
      values.addToPrice = +values.addToPrice;
      values.discountPercentage = +values.discountPercentage;

      this.sendExtraElements(values.extraElements);
      // values.stairHeight = +values.stairHeight;
      if (this.isAddMode) {
        // Creating new Quote

        this.quoteService
          .newQuote(values)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (data: QuoteItem) => {
              showSaveSuccess(this.messageService);

              this.router.navigate(['/dashboard/edit-quote', data.id], {
                relativeTo: this.route,
              });
            },
            error: () => {
              showSaveError(this.messageService);
            },
          });
      } else {
        // Saving Quote
        const id = this.preiseForm.get('id')?.value;
        this.quoteService
          .updateQuote(values, +id!)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              showSaveSuccess(this.messageService);
              setTimeout(() => {
                if (sendEmailAfterSave) {
                  this.sendEmail(id, true); // Send email after updating
                } else {
                  this.getPdf(this.quote.pdfFileName);
                  window.location.reload(); // Reload the page after saving
                }
              }, 1000);
            },
            error: () => {
              showSaveError(this.messageService);
            },
          });
      }
    } else {
      const invalidFields = markFormControlsAsDirty(this.preiseForm); // Collect invalid fields
      showRequiredFieldsError(this.messageService, invalidFields); // Show error with invalid fields
      this.switchToFirstInvalidTab();
    }
  }

  public prevPreise(): void {
    this.quoteService
      .getPrevId(this.quote.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: prevId => {
          if (prevId) {
            this.router.navigate(['/dashboard/edit-quote', prevId], { relativeTo: this.route });
          } else {
            this.showNoPrevQuote();
          }
        },
      });
  }

  public nextPreise(): void {
    this.quoteService
      .getNextId(this.quote.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: nextId => {
          if (nextId) {
            this.router.navigate(['/dashboard/edit-quote', nextId], { relativeTo: this.route });
          } else {
            this.showNoNextQuote();
          }
        },
      });
  }

  public sendQuoteViaEmail(sendEmailAfterSave: boolean) {
    const quoteId = this.quote.id;

    sendEmailAfterSave ? this.onSaveClick(true) : this.sendEmail(quoteId, false);
  }

  public showNoPrevQuote() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'There is no more previous quotes',
    });
  }

  public showNoNextQuote() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'There is no more next quotes',
    });
  }

  public getLastSentEdited(quote: QuoteItem) {
    const quoteSentLength = quote.status.quoteSent?.length;
    if (quoteSentLength) {
      this.lastQuoteSent = quote.status.quoteSent![quoteSentLength - 1];
    } else {
      this.lastQuoteSent = null;
    }

    const quoteEditedLength = quote.status.quoteEdited?.length;
    if (quoteEditedLength) {
      this.lastQuoteEdited = quote.status?.quoteEdited![quoteEditedLength - 1];
    } else {
      this.lastQuoteEdited = null;
    }
  }

  public openHistoryPdf(filename: string) {
    const url = this.buildPdfFileNameWithPath(filename);
    this.urlHelperService
      .get(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe(pdfBlob => {
        const urlFromBlob = URL.createObjectURL(pdfBlob);
        window.open(urlFromBlob);
      });
  }

  public showExtraElementsDialog() {
    this.extraElementsVisible = true;
  }

  public closeExtraElementsDialog() {
    this.extraElementsVisible = false;
  }

  private sendEmail(id: number, afterSave = false) {
    // Check if email field is not empty
    if (this.preiseForm.get('email')?.value) {
      // Email field has a value, proceed to send the email
      this.emailService
        .sendQuote(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            showSendSuccess(this.messageService);
            if (afterSave) {
              this.getPdf(this.quote.pdfFileName);
              window.location.reload(); // Reload page after email is sent
            }
          },
          error: () => {
            showSendError(this.messageService);
          },
        });
    } else {
      showEmailEmptyError(this.messageService);
    }
  }

  private showConfirmSave() {
    this.confirmationService.confirm({
      message: `Before sending email you have to save the quote. <br>Do you want to save this quote? <br><br>`,
      header: 'Save confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button p-button-text',
      rejectButtonStyleClass: 'p-button-danger p-button-text',
      acceptIcon: 'pi pi-save',
      rejectIcon: 'none',

      accept: () => {
        this.onSaveClick(false);
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'Quote NOT saved and Quote NOT sent',
        });
      },
    });
  }

  private ceilingCasingChanges() {
    // Initial
    this.showCeilingCasingLength = this.preiseForm.get('railingLength')?.value;
    // Subscription when ceilingCasing changes value
    this.preiseForm
      .get('ceilingCasing')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          const railingLength = this.preiseForm.get('railingLength')?.value;
          this.showCeilingCasingLength = value;
          this.preiseForm.controls['ceilingCasingLength'].setValidators([Validators.required]);
          this.preiseForm.controls['ceilingCasingLength'].setValue(railingLength, {
            emitEvent: false,
          });
          this.preiseForm.controls['ceilingCasingLength'].updateValueAndValidity({
            emitEvent: false,
          });
        } else {
          this.showCeilingCasingLength = value.ceilingCasing;
          this.preiseForm.controls['ceilingCasingLength'].setValidators(null);
          this.preiseForm.controls['ceilingCasingLength'].setValue(0, { emitEvent: false });
          this.preiseForm.controls['ceilingCasingLength'].updateValueAndValidity({
            emitEvent: false,
          });
        }
      });
  }

  private typeOfWoodChanges() {
    // Subscription when typeOfWood changes value
    this.preiseForm
      .get('stairWoodType')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value === 'buche' || value === 'esche') {
          this.preiseForm.controls['qngCertificate'].setValue(true, {
            emitEvent: false,
          });
          this.preiseForm.controls['qngCertificate'].updateValueAndValidity({
            emitEvent: false,
          });
        } else {
          this.preiseForm.controls['qngCertificate'].setValue(false, { emitEvent: false });
          this.preiseForm.controls['qngCertificate'].updateValueAndValidity({
            emitEvent: false,
          });
        }
      });
  }

  private typeOfStairsChanges() {
    // Subscription when type of stairs changes value
    const disableFieldsList = ['addToPrice'];

    this.preiseForm
      .get('stairType')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value === 'none') {
          disableFieldsList.forEach(formField => {
            this.preiseForm.get(formField)?.disable();
          });

          this.primaryOptionsNotRequired(this.validatorsChangeList);
        } else {
          disableFieldsList.forEach(formField => {
            this.preiseForm.get(formField)?.enable();
          });

          this.validatorsChangeList.forEach(formField => {
            this.preiseForm.controls[formField].setValidators([Validators.required]);

            this.preiseForm.controls[formField].updateValueAndValidity({
              emitEvent: false,
            });
          });
        }
      });
  }

  private primaryOptionsNotRequired(validatorsChangeList: string[]) {
    validatorsChangeList.forEach(formField => {
      this.preiseForm.controls[formField].setValidators(null);

      this.preiseForm.controls[formField].updateValueAndValidity({
        emitEvent: false,
      });
    });
  }

  private taxChanges() {
    // Initial
    this.isTax = this.preiseForm.get('tax')?.value;
    // Subscription when ceilingCasing changes value
    this.preiseForm
      .get('tax')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          // const taxPercentage = this.preiseForm.get('taxPercentage')?.value;
          this.isTax = value;
          this.preiseForm.controls['taxPercentage'].setValidators([Validators.required]);
          // this.preiseForm.controls['taxPercentage'].setValue(railingLength, {
          //   emitEvent: false,
          // });
          this.preiseForm.controls['taxPercentage'].updateValueAndValidity({
            emitEvent: false,
          });
        } else {
          this.isTax = value.tax;
          this.preiseForm.controls['taxPercentage'].setValidators(null);
          this.preiseForm.controls['taxPercentage'].setValue(0, { emitEvent: false });
          this.preiseForm.controls['taxPercentage'].updateValueAndValidity({
            emitEvent: false,
          });
        }
      });
  }

  private getPdf(fileName: string) {
    const iframeParams = '#navpanes=0';
    const randomNumber = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    const pdfPathAndFileName = this.buildPdfFileNameWithPath(fileName);
    const url = `${pdfPathAndFileName}${iframeParams}&${randomNumber}`;
    this.urlHelperService
      .get(url)
      .pipe(takeUntil(this.destroy$))
      .subscribe(pdfBlob => {
        const urlFromBlob = URL.createObjectURL(pdfBlob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlFromBlob);
      });
  }

  private buildPdfFileNameWithPath(fileName: string) {
    return `${environment.storageUrl}/storage/${fileName}`;
  }

  private switchToFirstInvalidTab() {
    const controls = this.preiseForm.controls;

    // Check which fields are invalid and identify the tab index to switch to
    const invalidFields = Object.keys(controls).filter(key => controls[key].invalid);

    if (invalidFields.length > 0) {
      if (
        invalidFields.includes('email') ||
        invalidFields.includes('postalCode') ||
        invalidFields.includes('installationDate')
      ) {
        this.activeTabIndex = 2; // Switch to the third tab (contact information)
      }
      // Add more conditions as necessary for other tabs
    }
  }

  private initExtraElementsMenuItems() {
    this.extraElementsMenuItems = [
      {
        label: 'neu hinzufügen',
        command: () => {
          this.addExtraElement();
        },
      },
      { separator: true },
      {
        label: 'vorhandene hinzufügen',
        command: () => {
          this.addExistingExtraElement();
        },
      },
    ];
  }

  private sendExtraElements(extraElements: ExtraElements[]) {
    extraElements.forEach(extraElement => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { addToDatabase, isEditing, ...dataToSend } = extraElement;
      if (addToDatabase) {
        this.extraElementsService
          .newExtraElement(dataToSend)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              showExtraElementSuccess(this.messageService);
            },
            error: error => {
              showExtraElementError(this.messageService);
              console.error('Error adding element to database:', error);
            },
          });
      }
    });
  }

  public deleteExtraElement(id: number) {
    this.extraElementsService
      .deleteExtraElement(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.addExistingExtraElement(false);
          showExtraElementDeleteSuccess(this.messageService);
        },
        error: error => {
          showExtraElementDeleteError(this.messageService);
          console.error(`Error deleting element from database: ${id}`, error);
        },
      });
  }

  // Method to save the edited item
  public saveExtraElement(extraElement: ExtraElements) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { addToDatabase, isEditing, id, ...dataToSend } = extraElement;

    extraElement.isEditing = false; // Exit edit mode after saving

    this.extraElementsService
      .updateExtraElement(dataToSend, id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.addExistingExtraElement(false);
          showExtraElementSuccess(this.messageService);
        },
        error: error => {
          showExtraElementError(this.messageService);
          console.error(`Error editing extra element: ${id}`, error);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
