/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpBase {
  constructor(private http: HttpClient) {}

  public baseUrl(): string {
    return environment.apiUrl;
  }

  public get<T = any>(path: string): Observable<T> {
    return <Observable<T>>this.http.get(`${this.baseUrl()}/${path}`, this.httpOptions());
  }

  public post<T = any>(path: string, body: any): Observable<T> {
    return <Observable<T>>this.http.post(`${this.baseUrl()}/${path}`, body, this.httpOptions());
  }

  public patch<T = any>(path: string, body: any): Observable<T> {
    return <Observable<T>>this.http.patch(`${this.baseUrl()}/${path}`, body, this.httpOptions());
  }

  public put<T = any>(path: string, body: any): Observable<T> {
    return <Observable<T>>this.http.put(`${this.baseUrl()}/${path}`, body, this.httpOptions());
  }

  public delete<T = any>(path: string): Observable<T> {
    return <Observable<T>>this.http.delete(`${this.baseUrl()}/${path}`, this.httpOptions());
  }

  protected httpOptions(): { headers: { [name: string]: string } } {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
}
