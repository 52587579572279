import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AdditionalInfo } from '../types/additionalInfo';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpBase) {}

  sendQuote(id: number) {
    const json = {
      id,
    };

    return this.http.post(`send-email/quote`, json);
  }

  getAdditionalInfo(): Observable<AdditionalInfo> {
    return this.http.get('additional-info');
  }

  saveAdditionalInfo(values: AdditionalInfo): Observable<AdditionalInfo> {
    return this.http.put(`additional-info`, values);
    
  }
}
