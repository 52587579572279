<div class="login-page flex flex-column">
  <img
    src="../../../assets/logo/holztreepen_logo_monotone-ai.png"
    alt="Holztreppenauspolen logo"
    srcset=""
    width="300px"
    class="mb-4"
  />
  <h1>Preise Panel</h1>
  <form
    [formGroup]="loginForm"
    (ngSubmit)="onLoginClick()"
    class="login-form flex flex-column p-4 gap-5"
  >
    <div>
      <span class="p-float-label mt-4">
        <input id="username" formControlName="username" pInputText placeholder="E-mail" />
        <label for="username">E-mail</label>
      </span>
      @if (
        loginForm.get('username')!.hasError('required') &&
        (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)
      ) {
        <app-form-error [type]="'empty'" [fieldName]="'E-mail'"></app-form-error>
      }
      @if (loginForm.get('username')?.hasError('email')) {
        <app-form-error [type]="'pattern'" [fieldName]="'E-mail'"></app-form-error>
      }
      @if (invalidCredentials) {
        <app-form-error [type]="'invalid'" [fieldName]="'E-mail'"></app-form-error>
      }
    </div>
    <div>
      <span class="p-float-label">
        <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
        <label for="password">Password</label>
      </span>
      @if (
        loginForm.get('password')!.invalid &&
        (loginForm.get('password')!.dirty || loginForm.get('password')!.touched)
      ) {
        <app-form-error [type]="'empty'" [fieldName]="'Password'"></app-form-error>
      }
      @if (invalidCredentials) {
        <app-form-error [type]="'invalid'" [fieldName]="'Password'"></app-form-error>
      }
    </div>
    <p-button label="Login" (click)="onLoginClick()"></p-button>
    <input [style.display]="'none'" type="submit" />
  </form>
</div>
