import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExtraElements } from '../types/quote';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class ExtraElementsService {
  constructor(private http: HttpBase) {}

  getAll(): Observable<ExtraElements[]> {
    return this.http.get(`extra-elements`);
  }

  updateExtraElement(values: ExtraElements, id: number) {
    return this.http.patch(`extra-elements/${id}`, values);
  }

  newExtraElement(values: ExtraElements) {
    return this.http.post(`extra-elements`, values);
  }

  deleteExtraElement(id: number) {
    return this.http.delete(`extra-elements/${id}`);
  }
}
