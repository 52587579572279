import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loginGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  //   if user is already authenticated - redirect to dashboard
  if (authService.isAuthenticated()) {
    router.navigate(['dashboard']);
    return false;
  }

  return true;
};
