<div class="preise-edit-container flex gap-2">
  <div class="form">
    <div class="flex flex-column gap-2 mb-5">
      @if (quote.status) {
        <div>
          Ist bearbeitet worden?
          @if (lastQuoteEdited) {
            <i class="pi pi-check" style="color: green"></i>
            ({{ lastQuoteEdited.author }} - {{ lastQuoteEdited.date | date: 'd/M/yy, H:mm' }})
          } @else {
            <i class="pi pi-times" style="color: #b53e3e"></i>
          }
        </div>
        <div>
          Ist verschickt worden?
          @if (lastQuoteSent) {
            <i class="pi pi-check" style="color: green"></i>
            ({{ lastQuoteSent.author }} - {{ lastQuoteSent.date | date: 'd/M/yy, H:mm' }})
          } @else {
            <i class="pi pi-times" style="color: #b53e3e"></i>
          }
        </div>
      }
    </div>

    @if (preiseForm) {
      <form
        [formGroup]="preiseForm"
        (ngSubmit)="onSaveClick(false)"
        class="card flex flex-column gap-2 mb-5"
      >
        <p-tabView [(activeIndex)]="activeTabIndex">
          <p-tabPanel header="Formular">
            <div class="form-container flex flex-wrap gap-2">
              <label for="stairType">Art der Treppe</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairType"
                  [options]="stairTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Treppentyp auswählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairWoodType">Holzart</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairWoodType"
                  [options]="stairWoodTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Holzart der Treppe auswählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairForm">Form</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairForm"
                  [options]="stairForms"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Treppenform wählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="railingType">Brüstungsgeländertyp</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="railingType"
                  [options]="railingTypes"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Geländertyp auswählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="railingLength">Brüstungsgeländerlänge [LFDM.]</label>
              <p-inputGroup>
                <input formControlName="railingLength" type="number" pInputText />
              </p-inputGroup>

              <label for="ceilingCasing">Deckenrandverkleidung</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="ceilingCasing" />
              </p-inputGroup>

              @if (showCeilingCasingLength) {
                <label for="ceilingCasingLength">Deckenrandverkleidung Länge</label>
                <p-inputGroup>
                  <input formControlName="ceilingCasingLength" type="number" pInputText />
                </p-inputGroup>
              }

              <label for="reisers">Setzstufen</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="reisers" />
              </p-inputGroup>

              <label for="stairHeight">Treppenhöhe</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairHeight"
                  [options]="stairHeights"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Treppenhöhe wählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="woodColor">Farbe</label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="woodColor"
                  [options]="woodColors"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Farbe auswählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="stairAmount">Anzahl der Treppen </label>
              <p-inputGroup>
                <p-dropdown
                  formControlName="stairAmount"
                  [options]="stairAmount"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Anzahl der Treppen auswählen "
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="extraInfo">Zusätzliche Fragen oder Infos</label>
              <p-inputGroup>
                <textarea rows="5" cols="30" pInputTextarea formControlName="extraInfo"> </textarea>
              </p-inputGroup>

              <label for="remarks">Anmerkungen</label>
              <p-inputGroup>
                <textarea rows="5" cols="30" pInputTextarea formControlName="remarks"> </textarea>
              </p-inputGroup>

              <label for="qngCertificate">QNG-Zertifikat?</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="qngCertificate" />
              </p-inputGroup>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Zusätzliche Elemente" [disabled]="isAddMode">
            <div formArrayName="extraElements">
              <div
                *ngFor="let element of extraElements.controls; let i = index; let last = last"
                [formGroupName]="i"
                [ngClass]="{ 'extra-elements-display': !last }"
                class="flex flex-wrap flex-initial gap-3 mb-5 pb-5"
              >
                <div class="w-5">
                  <label for="name-{{ i }}">Name</label>
                  <p-inputGroup>
                    <input formControlName="name" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="value-{{ i }}">Wert</label>
                  <p-inputGroup>
                    <input formControlName="value" type="number" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="quantity-{{ i }}">Menge</label>
                  <p-inputGroup>
                    <input formControlName="quantity" type="number" pInputText />
                  </p-inputGroup>
                </div>

                <div class="w-5">
                  <label for="quantityUnit-{{ i }}"> Menge Einheit</label>
                  <p-inputGroup>
                    <input formControlName="quantityUnit" pInputText />
                  </p-inputGroup>
                </div>
                <span class="pi pi-trash clickable" (click)="removeExtraElement(i)"></span>
                <span>Zur Datenbank hinzufügen?</span>
                <p-checkbox formControlName="addToDatabase" binary="true" />
              </div>

              <!-- <span class="pi pi-plus clickable" (click)="addExtraElement()"></span> -->
              <p-splitButton
                label="hinzufügen"
                (onClick)="addExtraElement()"
                [model]="extraElementsMenuItems"
                severity="secondary"
              />
            </div>
          </p-tabPanel>
          <p-tabPanel header="Kontaktdaten">
            <div class="form-container flex flex-wrap gap-2">
              <label for="id">ID</label>
              <p-inputGroup>
                <input formControlName="id" type="text" pInputText />
              </p-inputGroup>

              <label for="offerNumber">Kostenvoranschlag</label>
              <p-inputGroup>
                <input formControlName="offerNumber" type="text" pInputText />
              </p-inputGroup>

              <label for="id">Kundennummer</label>
              <p-inputGroup formGroupName="client">
                <input formControlName="id" type="number" pInputText />
              </p-inputGroup>

              <label for="salutation">Anrede</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <p-dropdown
                  formControlName="salutation"
                  [options]="salutationOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Anrede auswählen"
                  [style]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="name">Vorname</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <input
                  formControlName="name"
                  pInputText
                  placeholder="Vorname"
                  autocomplete="family-name"
                />
              </p-inputGroup>

              <label for="surname">Name</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <input
                  formControlName="surname"
                  pInputText
                  placeholder="Name"
                  autocomplete="given-name"
                />
                <!-- Autocomplete for Name and Surname is reversed for Marcins autocmplete -->
              </p-inputGroup>

              <label for="email">Email</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-at"></i>
                </p-inputGroupAddon>
                <input
                  formControlName="email"
                  pInputText
                  placeholder="E-mail"
                  autocomplete="email"
                />
              </p-inputGroup>

              <label for="phoneNumber">Telefon</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-phone"></i>
                </p-inputGroupAddon>
                <input formControlName="phoneNumber" pInputText placeholder="Telefon" />
              </p-inputGroup>

              <label for="postalCode">PLZ</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-directions"></i>
                </p-inputGroupAddon>
                <input formControlName="postalCode" pInputText placeholder="PLZ" />
              </p-inputGroup>

              <label for="installationDate">Montagetermin</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  <i class="pi pi-calendar"></i>
                </p-inputGroupAddon>
                <p-calendar
                  formControlName="installationDate"
                  dateFormat="dd.mm.yy"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                />
              </p-inputGroup>

              <label for="ip">IP-Adresse</label>
              <p-inputGroup>
                <p-inputGroupAddon>
                  {{ quote.countryCode }}
                </p-inputGroupAddon>
                <input formControlName="ip" pInputText placeholder="IP-Adresse" />
              </p-inputGroup>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Skonto/Steuer">
            <div class="form-container flex flex-wrap gap-2">
              <label for="discountPercentage">Abschlag (%)</label>
              <p-inputGroup>
                <input formControlName="discountPercentage" type="number" pInputText />
              </p-inputGroup>

              <label for="addToPrice">Zum Preis hinzufügen</label>
              <p-inputGroup>
                <input formControlName="addToPrice" type="number" pInputText />
              </p-inputGroup>

              <label for="tax">Steuer?</label>
              <p-inputGroup>
                <p-inputSwitch formControlName="tax" />
              </p-inputGroup>

              @if (isTax) {
                <label for="taxPercentage">Steuerprozentsatz (%)</label>
                <p-inputGroup>
                  <input formControlName="taxPercentage" type="number" pInputText />
                </p-inputGroup>
              }
            </div>
          </p-tabPanel>
        </p-tabView>

        <div class="submit">
          <p-button label="Speichern" icon="pi pi-save" (click)="onSaveClick(false)"></p-button>
          <input [style.display]="'none'" type="submit" />
        </div>

        @if (!isAddMode) {
          <div class="send-quote">
            <p-button
              label="E-Mail senden"
              icon="pi pi-at"
              (click)="sendQuoteViaEmail(true)"
            ></p-button>
          </div>
        }
      </form>
    }
  </div>
  <div class="pdf-viewer">
    @if (!isAddMode) {
      <div class="navigation flex gap-3 justify-content-end">
        <div class="prev-quote">
          <p-button
            severity="secondary"
            size="small"
            (click)="prevPreise()"
            [icon]="'pi pi-chevron-left'"
          ></p-button>
        </div>
        <div class="next-quote">
          <p-button
            severity="secondary"
            size="small"
            (click)="nextPreise()"
            [icon]="'pi pi-chevron-right'"
            iconPos="right"
          ></p-button>
        </div>
      </div>
    }

    @if (!isAddMode) {
      <div><h3>Angebot PDF</h3></div>
      @if (pdfUrl) {
        <iframe [src]="pdfUrl" width="100%" height="1000px" frameBorder="0"></iframe>
      }

      @if (quote.status && quote.status.quoteSent) {
        <div class="sentHistory">
          <h3>Alle gesendeten Angebote</h3>
          @for (quoteSent of quote.status.quoteSent; track quoteSent; let i = $index) {
            <div class="sentHistoryElement">
              {{ i + 1 }}) {{ quoteSent.date | date: 'd/M/yy, H:mm' }} ({{ quoteSent.author }}):
              <span
                class="clickable"
                (click)="openHistoryPdf(quoteSent.pdf)"
                (keydown)="openHistoryPdf(quoteSent.pdf)"
                >Open PDF <i class="pi pi-file-pdf"></i
              ></span>
              ({{ quoteSent.pdf }}.pdf)
            </div>
          }
        </div>
      }
    }
  </div>
</div>

<p-dialog
  header="Zusätzliches Element wählen"
  [modal]="true"
  [(visible)]="extraElementsVisible"
  [style]="{ width: '35rem' }"
>
  @for (
    extraElement of extraElementsItems;
    track extraElement;
    let i = $index;
    let first = $first;
    let last = $last
  ) {
    <div [ngClass]="{ 'pt-2': first, 'with-border': !last }" class="mb-2 pb-2 extra-element-item">
      <!-- <div class="">
        {{ extraElement.name }} {{ extraElement.value }}
        {{ extraElement.quantity }}
        {{ extraElement.quantityUnit }}
      </div>

      <div class="actions">
        <p-button
          label="Select"
          severity="secondary"
          size="small"
          (click)="addExtraElement(extraElement, true)"
        >
        </p-button>
        <span
          class="pi pi-trash clickable ml-2"
          (click)="deleteExtraElement(extraElement.id!)"
        ></span>
      </div> -->
      <div>
        <ng-container *ngIf="!extraElement.isEditing; else editTemplate">
          <!-- Display View Mode -->
          {{ extraElement.name }} {{ extraElement.value }} {{ extraElement.quantity }}
          {{ extraElement.quantityUnit }}
        </ng-container>

        <!-- Edit Template -->
        <ng-template #editTemplate>
          <div class="flex flex-wrap flex-initial gap-3">
            <div class="w-5">
              <span>Name</span>
              <p-inputGroup>
                <input pInputText type="text" [(ngModel)]="extraElement.name" class="input-field" />
              </p-inputGroup>
            </div>

            <div class="w-5">
              <span>Wert</span>
              <p-inputGroup>
                <input
                  pInputText
                  type="number"
                  [(ngModel)]="extraElement.value"
                  class="input-field"
                />
              </p-inputGroup>
            </div>

            <div class="w-5">
              <span>Menge</span>
              <p-inputGroup>
                <input
                  pInputText
                  type="number"
                  [(ngModel)]="extraElement.quantity"
                  class="input-field"
                />
              </p-inputGroup>
            </div>

            <div class="w-5">
              <span>Menge Einheit</span>
              <p-inputGroup>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="extraElement.quantityUnit"
                  class="input-field"
                />
              </p-inputGroup>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="actions">
        <ng-container *ngIf="!extraElement.isEditing">
          <p-button
            label="Auswählen"
            severity="secondary"
            size="small"
            [outlined]="true"
            (click)="addExtraElement(extraElement, true)"
            class="mr-2"
          >
          </p-button
        ></ng-container>

        <!-- Save Button when in Edit Mode -->
        <ng-template #saveTemplate>
          <p-button
            label="Speichern"
            severity="primary"
            size="small"
            (click)="saveExtraElement(extraElement)"
          ></p-button>
        </ng-template>

        <ng-container *ngIf="!extraElement.isEditing; else saveTemplate">
          <i
            class="pi pi-file-edit cursor-pointer"
            style="font-size: 1.5rem"
            (click)="extraElement.isEditing = true"
          ></i>
        </ng-container>

        <!-- Delete Button -->
        <span
          class="pi pi-trash clickable ml-2"
          (click)="deleteExtraElement(extraElement.id!)"
        ></span>
      </div>
    </div>
  }
  <div class="flex justify-content-end gap-2">
    <p-button label="Abbrechen" severity="secondary" (onClick)="closeExtraElementsDialog()" />
  </div>
</p-dialog>

<p-confirmDialog />
