@if (pricesForm) {
  <form
    [formGroup]="pricesForm"
    (ngSubmit)="onSaveClick()"
    class="card flex flex-column gap-3 mb-5"
  >
    <p-tabView>
      <p-tabPanel header="Art der Treppe">
        <div formGroupName="stairType">
          <div class="form-container flex flex-wrap gap-2">
            <label for="holzwangentreppe">Holzwangentreppe</label>
            <p-inputGroup>
              <input formControlName="holzwangentreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="stahlwangentreppe">Stahlwangentreppe</label>
            <p-inputGroup>
              <input formControlName="stahlwangentreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="mittelholmtreppe">Mittelholmtreppe</label>
            <p-inputGroup>
              <input formControlName="mittelholmtreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="faltwerktreppe">Faltwerktreppe</label>
            <p-inputGroup>
              <input formControlName="faltwerktreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="faltwerkoptiktreppe">Faltwerkoptiktreppe</label>
            <p-inputGroup>
              <input formControlName="faltwerkoptiktreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="kragarmtreppe">Kragarmtreppe</label>
            <p-inputGroup>
              <input formControlName="kragarmtreppe" type="number" pInputText />
            </p-inputGroup>

            <label for="beton">Beton</label>
            <p-inputGroup>
              <input formControlName="beton" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Holzart">
        <div formGroupName="stairWoodType">
          <div class="form-container flex flex-wrap gap-2">
            <label for="kiefer">Kiefer</label>
            <p-inputGroup>
              <input formControlName="kiefer" type="number" pInputText />
            </p-inputGroup>

            <label for="buche">Buche</label>
            <p-inputGroup>
              <input formControlName="buche" type="number" pInputText />
            </p-inputGroup>

            <label for="ahorn">Ahorn</label>
            <p-inputGroup>
              <input formControlName="ahorn" type="number" pInputText />
            </p-inputGroup>

            <label for="esche">Esche</label>
            <p-inputGroup>
              <input formControlName="esche" type="number" pInputText />
            </p-inputGroup>

            <label for="eiche">Eiche</label>
            <p-inputGroup>
              <input formControlName="eiche" type="number" pInputText />
            </p-inputGroup>

            <label for="kirsche">Kirsche</label>
            <p-inputGroup>
              <input formControlName="kirsche" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Form">
        <div formGroupName="stairForm">
          <div class="form-container flex flex-wrap gap-2">
            <label for="gerade">Gerade</label>
            <p-inputGroup>
              <input formControlName="gerade" type="number" pInputText />
            </p-inputGroup>

            <label for="1/4">1/4</label>
            <p-inputGroup>
              <input formControlName="1/4" type="number" pInputText />
            </p-inputGroup>

            <label for="2/4">2/4</label>
            <p-inputGroup>
              <input formControlName="2/4" type="number" pInputText />
            </p-inputGroup>

            <label for="1/2">1/2</label>
            <p-inputGroup>
              <input formControlName="1/2" type="number" pInputText />
            </p-inputGroup>

            <label for="1/2 mit podest">1/2 mit Podest</label>
            <p-inputGroup>
              <input formControlName="1/2 mit podest" type="number" pInputText />
            </p-inputGroup>

            <label for="spindel">Spindel</label>
            <p-inputGroup>
              <input formControlName="spindel" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Brüstungsgeländertyp">
        <div formGroupName="railingTypes">
          <div class="form-container flex flex-wrap gap-2">
            <div formGroupName="standardHolz" class="flex flex-wrap gap-2">
              <label for="kiefer">Standard Holz Kiefer</label>
              <p-inputGroup>
                <input formControlName="kiefer" type="number" pInputText />
              </p-inputGroup>

              <label for="buche">Standard Holz Buche</label>
              <p-inputGroup>
                <input formControlName="buche" type="number" pInputText />
              </p-inputGroup>

              <label for="ahorn">Standard Holz Ahorn</label>
              <p-inputGroup>
                <input formControlName="ahorn" type="number" pInputText />
              </p-inputGroup>

              <label for="esche">Standard Holz Esche</label>
              <p-inputGroup>
                <input formControlName="esche" type="number" pInputText />
              </p-inputGroup>

              <label for="eiche">Standard Holz Eiche</label>
              <p-inputGroup>
                <input formControlName="eiche" type="number" pInputText />
              </p-inputGroup>

              <label for="kirsche">Standard Holz Kirsche</label>
              <p-inputGroup>
                <input formControlName="kirsche" type="number" pInputText />
              </p-inputGroup>
            </div>

            <label for="stahlstabe">Stahlstäbe</label>
            <p-inputGroup>
              <input formControlName="stahlstabe" type="number" pInputText />
            </p-inputGroup>

            <label for="glas mit holz">Glas mit Holz</label>
            <p-inputGroup>
              <input formControlName="glas mit holz" type="number" pInputText />
            </p-inputGroup>

            <label for="glas mit edelstahl">Glas mit Edelstahl</label>
            <p-inputGroup>
              <input formControlName="glas mit edelstahl" type="number" pInputText />
            </p-inputGroup>

            <label for="sicherheitsglas seitlich befestigt"
              >Sicherheitsglas seitlich befestigt</label
            >
            <p-inputGroup>
              <input
                formControlName="sicherheitsglas seitlich befestigt"
                type="number"
                pInputText
              />
            </p-inputGroup>

            <label for="glas mit stahlprofil">Glas mit Stahlprofil</label>
            <p-inputGroup>
              <input formControlName="glas mit stahlprofil" type="number" pInputText />
            </p-inputGroup>

            <label for="sicherheitsglas eingelassen">Sicherheitsglas eingelassen</label>
            <p-inputGroup>
              <input formControlName="sicherheitsglas eingelassen" type="number" pInputText />
            </p-inputGroup>

            <label for="relinggelander">Relinggeländer</label>
            <p-inputGroup>
              <input formControlName="relinggelander" type="number" pInputText />
            </p-inputGroup>

            <label for="vollholzgelander">Vollholzgeländer</label>
            <p-inputGroup>
              <input formControlName="vollholzgelander" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Deckenrandverkleidung">
        <div formGroupName="ceilingCasingWoodType">
          <div class="form-container flex flex-wrap gap-2">
            <label for="kiefer">Deckenrandverkleidung Kiefer</label>
            <p-inputGroup>
              <input formControlName="kiefer" type="number" pInputText />
            </p-inputGroup>

            <label for="buche">Deckenrandverkleidung Buche</label>
            <p-inputGroup>
              <input formControlName="buche" type="number" pInputText />
            </p-inputGroup>

            <label for="ahorn">Deckenrandverkleidung Ahorn</label>
            <p-inputGroup>
              <input formControlName="ahorn" type="number" pInputText />
            </p-inputGroup>

            <label for="esche">Deckenrandverkleidung Esche</label>
            <p-inputGroup>
              <input formControlName="esche" type="number" pInputText />
            </p-inputGroup>

            <label for="eiche">Deckenrandverkleidung Eiche</label>
            <p-inputGroup>
              <input formControlName="eiche" type="number" pInputText />
            </p-inputGroup>

            <label for="kirsche">Deckenrandverkleidung Kirsche</label>
            <p-inputGroup>
              <input formControlName="kirsche" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Setzstufen">
        <div formGroupName="risersWoodType">
          <div class="form-container flex flex-wrap gap-2">
            <label for="kiefer">Setzstufen Kiefer</label>
            <p-inputGroup>
              <input formControlName="kiefer" type="number" pInputText />
            </p-inputGroup>

            <label for="buche">Setzstufen Buche</label>
            <p-inputGroup>
              <input formControlName="buche" type="number" pInputText />
            </p-inputGroup>

            <label for="ahorn">Setzstufen Ahorn</label>
            <p-inputGroup>
              <input formControlName="ahorn" type="number" pInputText />
            </p-inputGroup>

            <label for="esche">Setzstufen Esche</label>
            <p-inputGroup>
              <input formControlName="esche" type="number" pInputText />
            </p-inputGroup>

            <label for="eiche">Setzstufen Eiche</label>
            <p-inputGroup>
              <input formControlName="eiche" type="number" pInputText />
            </p-inputGroup>

            <label for="kirsche">Setzstufen Kirsche</label>
            <p-inputGroup>
              <input formControlName="kirsche" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Holzart Farbe">
        <div formGroupName="woodColor">
          <div class="form-container flex flex-wrap gap-2">
            <label for="kiefer">Holzart Farbe Kiefer</label>
            <p-inputGroup>
              <input formControlName="kiefer" type="number" pInputText />
            </p-inputGroup>

            <label for="buche">Holzart Farbe Buche</label>
            <p-inputGroup>
              <input formControlName="buche" type="number" pInputText />
            </p-inputGroup>

            <label for="ahorn">Holzart Farbe Ahorn</label>
            <p-inputGroup>
              <input formControlName="ahorn" type="number" pInputText />
            </p-inputGroup>

            <label for="esche">Holzart Farbe Esche</label>
            <p-inputGroup>
              <input formControlName="esche" type="number" pInputText />
            </p-inputGroup>

            <label for="eiche">Holzart Farbe Eiche</label>
            <p-inputGroup>
              <input formControlName="eiche" type="number" pInputText />
            </p-inputGroup>

            <label for="kirsche">Holzart Farbe Kirsche</label>
            <p-inputGroup>
              <input formControlName="kirsche" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="PLZ">
        <div formGroupName="postalCodes">
          <div class="form-container flex flex-wrap gap-2">
            <label for="pricePerKilometer">Preis pro km (Euro)</label>
            <p-inputGroup>
              <input formControlName="pricePerKilometer" type="number" pInputText />
            </p-inputGroup>

            <label for="prices">PLZ</label>
            <p-inputGroup>
              <textarea rows="50" cols="10" pInputTextarea formControlName="prices"> </textarea>
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Montage">
        <div formGroupName="assembly">
          <div class="form-container flex flex-wrap gap-2">
            <label for="cost">Montagekosten (Euro)</label>
            <p-inputGroup>
              <input formControlName="cost" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Steiggelander">
        <div formGroupName="steiggelander">
          <div class="form-container flex flex-wrap gap-2">
            <label for="gerade">Gerade</label>
            <p-inputGroup>
              <input formControlName="gerade" type="number" pInputText />
            </p-inputGroup>

            <label for="1/4">1/4</label>
            <p-inputGroup>
              <input formControlName="1/4" type="number" pInputText />
            </p-inputGroup>

            <label for="2/4">2/4</label>
            <p-inputGroup>
              <input formControlName="2/4" type="number" pInputText />
            </p-inputGroup>

            <label for="1/2">1/2</label>
            <p-inputGroup>
              <input formControlName="1/2" type="number" pInputText />
            </p-inputGroup>

            <label for="1/2 mit podest">1/2 mit Podest</label>
            <p-inputGroup>
              <input formControlName="1/2 mit podest" type="number" pInputText />
            </p-inputGroup>

            <label for="spindel">Spindel</label>
            <p-inputGroup>
              <input formControlName="spindel" type="number" pInputText />
            </p-inputGroup>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>

    <div class="submit pt-5">
      <p-button label="Speichern" (click)="onSaveClick()"></p-button>
      <input [style.display]="'none'" type="submit" />
    </div>
  </form>
}
