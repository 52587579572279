import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Prices } from '../types/prices';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class PricesService {
  constructor(private http: HttpBase) {}

  getPrices(): Observable<any> {
    const path = 'pricing';
    return this.http.get(path);
  }

  updatePrices(values: Prices) {
    const pricing = {
      pricingConfig: {
        ...values,
      },
    };
    const path = 'pricing';
    return this.http.put(path, pricing);
  }
}
