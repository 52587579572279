import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { PricesService } from 'src/app/services/prices.service';
import { Prices } from 'src/app/types/prices';
import { markFormControlsAsDirty } from 'src/app/utils/forms';
import { showSaveSuccess, showSaveError, showRequiredFieldsError } from 'src/app/utils/toasts';

@Component({
  selector: 'app-prices-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    InputGroupModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule,
  ],
  templateUrl: './prices-edit.component.html',
  styleUrl: './prices-edit.component.less',
})
export class PricesEditComponent implements OnInit {
  public pricesForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(PricesService) private pricesService: PricesService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.pricesService.getPrices().subscribe({
      next: (data: { pricingConfig: Prices }) => {
        this.buildForm(data.pricingConfig);
      },
      error: () => {
        console.warn('Error while getting prices');
      },
    });
  }

  // eslint-disable-next-line max-lines-per-function
  public buildForm(data: Prices) {
    this.pricesForm = this.formBuilder.group({
      stairType: this.formBuilder.group({
        holzwangentreppe: [data.stairType.holzwangentreppe, [Validators.required]],
        stahlwangentreppe: [data.stairType.stahlwangentreppe, [Validators.required]],
        mittelholmtreppe: [data.stairType.mittelholmtreppe, [Validators.required]],
        faltwerktreppe: [data.stairType.faltwerktreppe, [Validators.required]],
        faltwerkoptiktreppe: [data.stairType.faltwerkoptiktreppe, [Validators.required]],
        kragarmtreppe: [data.stairType.kragarmtreppe, [Validators.required]],
        beton: [data.stairType.beton, [Validators.required]],
      }),
      stairWoodType: this.formBuilder.group({
        kiefer: [data.stairWoodType.kiefer, [Validators.required]],
        buche: [data.stairWoodType.buche, [Validators.required]],
        ahorn: [data.stairWoodType.ahorn, [Validators.required]],
        esche: [data.stairWoodType.esche, [Validators.required]],
        eiche: [data.stairWoodType.eiche, [Validators.required]],
        kirsche: [data.stairWoodType.kirsche, [Validators.required]],
      }),
      stairForm: this.formBuilder.group({
        gerade: [data.stairForm.gerade, [Validators.required]],
        '1/4': [data.stairForm['1/4'], [Validators.required]],
        '2/4': [data.stairForm['2/4'], [Validators.required]],
        '1/2': [data.stairForm['1/2'], [Validators.required]],
        '1/2 mit podest': [data.stairForm['1/2 mit podest'], [Validators.required]],
        spindel: [data.stairForm.spindel, [Validators.required]],
      }),
      railingTypes: this.formBuilder.group({
        standardHolz: this.formBuilder.group({
          kiefer: [data.railingTypes.standardHolz.kiefer, [Validators.required]],
          buche: [data.railingTypes.standardHolz.buche, [Validators.required]],
          ahorn: [data.railingTypes.standardHolz.ahorn, [Validators.required]],
          esche: [data.railingTypes.standardHolz.esche, [Validators.required]],
          eiche: [data.railingTypes.standardHolz.eiche, [Validators.required]],
          kirsche: [data.railingTypes.standardHolz.kirsche, [Validators.required]],
        }),
        // edelstahl: [data.railingTypes.edelstahl, [Validators.required]],
        // 'glas mit Holz': [data.railingTypes.['gl'], [Validators.required]],
        // 'glas mit Edelstahl': [0, [Validators.required]],
        // stahl: [data.railingTypes.stahl, [Validators.required]],
        // sicherheitsglas: [data.railingTypes.sicherheitsglas, [Validators.required]],
        stahlstabe: [data.railingTypes.stahlstabe, [Validators.required]],
        'glas mit holz': [data.railingTypes['glas mit holz'], [Validators.required]],
        'glas mit edelstahl': [data.railingTypes['glas mit edelstahl'], [Validators.required]],
        'sicherheitsglas seitlich befestigt': [
          data.railingTypes['sicherheitsglas seitlich befestigt'],
          [Validators.required],
        ],
        'glas mit stahlprofil': [data.railingTypes['glas mit stahlprofil'], [Validators.required]],
        'sicherheitsglas eingelassen': [
          data.railingTypes['sicherheitsglas eingelassen'],
          [Validators.required],
        ],
        relinggelander: [data.railingTypes.relinggelander, [Validators.required]],
        vollholzgelander: [data.railingTypes.vollholzgelander, [Validators.required]],
      }),
      // deckerrandverkleidung
      ceilingCasingWoodType: this.formBuilder.group({
        kiefer: [data.ceilingCasingWoodType.kiefer, [Validators.required]],
        buche: [data.ceilingCasingWoodType.buche, [Validators.required]],
        ahorn: [data.ceilingCasingWoodType.ahorn, [Validators.required]],
        esche: [data.ceilingCasingWoodType.esche, [Validators.required]],
        eiche: [data.ceilingCasingWoodType.eiche, [Validators.required]],
        kirsche: [data.ceilingCasingWoodType.kirsche, [Validators.required]],
      }),
      // setztufen
      risersWoodType: this.formBuilder.group({
        kiefer: [data.risersWoodType.kiefer, [Validators.required]],
        buche: [data.risersWoodType.buche, [Validators.required]],
        ahorn: [data.risersWoodType.ahorn, [Validators.required]],
        esche: [data.risersWoodType.esche, [Validators.required]],
        eiche: [data.risersWoodType.eiche, [Validators.required]],
        kirsche: [data.risersWoodType.kirsche, [Validators.required]],
      }),
      // Farbe / wood color
      woodColor: this.formBuilder.group({
        kiefer: [data.woodColor.kiefer, [Validators.required]],
        buche: [data.woodColor.buche, [Validators.required]],
        ahorn: [data.woodColor.ahorn, [Validators.required]],
        esche: [data.woodColor.esche, [Validators.required]],
        eiche: [data.woodColor.eiche, [Validators.required]],
        kirsche: [data.woodColor.kirsche, [Validators.required]],
      }),
      // kilometers
      postalCodes: this.formBuilder.group({
        prices: [data.postalCodes.prices, [Validators.required]],
        pricePerKilometer: [data.postalCodes.pricePerKilometer, [Validators.required]],
      }),
      assembly: this.formBuilder.group({
        cost: [data.assembly.cost, [Validators.required]],
      }),
      steiggelander: this.formBuilder.group({
        gerade: [data.steiggelander.gerade, [Validators.required]],
        '1/4': [data.steiggelander['1/4'], [Validators.required]],
        '2/4': [data.steiggelander['2/4'], [Validators.required]],
        '1/2': [data.steiggelander['1/2'], [Validators.required]],
        '1/2 mit podest': [data.steiggelander['1/2 mit podest'], [Validators.required]],
        spindel: [data.steiggelander.spindel, [Validators.required]],
      }),
    });
  }

  public onSaveClick(): void {
    if (this.pricesForm.valid) {
      const values = this.pricesForm.value;
      this.pricesService.updatePrices(values).subscribe({
        next: () => {
          showSaveSuccess(this.messageService);
        },
        error: () => {
          showSaveError(this.messageService);
        },
      });
    } else {
      const invalidFields = markFormControlsAsDirty(this.pricesForm); // Collect invalid fields
      showRequiredFieldsError(this.messageService, invalidFields); // Show error with invalid fields
    }
  }
}
