import { MessageService } from 'primeng/api/messageservice';

export function showSaveSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Save sucessfull',
  });
}

export function showSaveError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Save NOT sucessfull',
  });
}

export function showExtraElementSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Save/update extra element sucessfull',
  });
}

export function showExtraElementError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Save/update extra element NOT successfull',
  });
}

export function showExtraElementDeleteSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Delete extra element sucessfull',
  });
}

export function showExtraElementDeleteError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Delete extra element NOT successfull',
  });
}

export function showRequiredFieldsError(messageService: MessageService, fields: string[] = []) {
  const fieldList = fields.length ? `: ${fields.join(', ')}` : ''; // Format the invalid fields
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: `Save NOT successful. Please fill out the required fields${fieldList}.`,
  });
}

export function showSendSuccess(messageService: MessageService) {
  messageService.add({
    severity: 'success',
    summary: 'Success',
    detail: 'Email sent sucessfully',
  });
}

export function showSendError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Email was NOT sent',
  });
}

export function showEmailEmptyError(messageService: MessageService) {
  messageService.add({
    severity: 'error',
    summary: 'Error',
    detail: 'Email field is not filled',
  });
}
