// service
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class UrlHelperService {
  constructor(
    private http: HttpClient,
    public authService: AuthService,
  ) {}

  get(url: string): Observable<Blob> {
    const authToken = this.authService.getToken();
    const options: { headers: HttpHeaders } = {
      headers: new HttpHeaders(),
    };

    options.headers.append('Authorization', authToken!);
    return this.http.get(url, { ...options, responseType: 'blob' });
  }
}
