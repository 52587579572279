import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { AuthService, JWT_TOKEN_LS_KEY, USERNAME_LS_KEY } from 'src/app/services/auth.service';
import { markFormControlsAsDirty } from 'src/app/utils/forms';
import { HttpBase } from 'src/app/utils/http-base';

import { FormErrorComponent } from '../common/form-error/form-error.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule,
    InputTextModule,
    InputGroupModule,
    InputGroupAddonModule,
    FormErrorComponent,
  ],
  providers: [HttpBase],
  templateUrl: './login.component.html',
  styleUrl: './login.component.less',
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public invalidCredentials: boolean = false;
  public authError: string;
  public logoutSuccess!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.route.queryParams.subscribe((params: Params) => {
      this.authError = params['authError'];
      this.logoutSuccess = params['logoutSuccessfull'];

      if (this.authError === 'true') {
        this.showAuthError();
      }

      if (this.logoutSuccess === 'true') {
        this.showLogoutSuccess();
      }
    });
  }

  public onLoginClick(): void {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      this.authService.login(username, password).subscribe(
        response => {
          const token = response.access_token;

          if (token) {
            this.showLoginSuccess();

            localStorage.setItem(JWT_TOKEN_LS_KEY, token);
            localStorage.setItem(USERNAME_LS_KEY, response.username);
            this.router.navigate(['./dashboard']);
          }
        },
        () => {
          this.showInvalidCredentialsError();
          this.invalidCredentials = true;
        },
      );
    } else {
      markFormControlsAsDirty(this.loginForm);
      // this.markFormControlsAsDirty();
    }
  }

  // private markFormControlsAsDirty() {
  //   Object.keys(this.loginForm.controls).forEach(key => {
  //     this.markControlDirty(<FormControl>this.loginForm.get(key));
  //   });
  // }

  // private markControlDirty(formControl: FormControl) {
  //   formControl.markAsDirty();
  // }

  public showAuthError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'User not authenticated. Redirected to login',
    });
  }

  private showInvalidCredentialsError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Invalid e-mail or password. Please try again',
    });
  }

  public showLoginSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Login sucessfull',
    });
  }

  public showLogoutSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Logout sucessfull',
    });
  }
}
