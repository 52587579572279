@if (editPdfEmailInfoForm) {
  <form
    [formGroup]="editPdfEmailInfoForm"
    (ngSubmit)="onSaveClick()"
    class="card flex flex-column gap-3 mb-5"
  >
    <p-tabView>
      <p-tabPanel header="PDF/E-mail info">
        <div class="flex flex-column gap-3">
          <label for="createdBy">Erstellt von</label>
          <p-inputGroup>
            <input formControlName="createdBy" type="text" pInputText />
          </p-inputGroup>

          <label for="customerNumber">Kunden-Nr</label>
          <p-inputGroup>
            <input formControlName="customerNumber" type="text" pInputText />
          </p-inputGroup>

          <label for="contactInfo">Kontaktinformationen</label>
          <p-inputGroup>
            <p-editor formControlName="contactInfo" [style]="{ height: '320px' }"> </p-editor>
          </p-inputGroup>

          <label for="textUnderTables">Text unter Tabellen</label>
          <p-inputGroup>
            <p-editor formControlName="textUnderTables" [style]="{ height: '320px' }" />
          </p-inputGroup>

          <label for="textUnderTables2">Text unter Tabellen 2</label>
          <p-inputGroup>
            <p-editor formControlName="textUnderTables2" [style]="{ height: '320px' }" />
          </p-inputGroup>

          <label for="mailContentBeforeTable">Mailinhalt vor Tabelle</label>
          <p-inputGroup>
            <p-editor formControlName="mailContentBeforeTable" [style]="{ height: '320px' }" />
          </p-inputGroup>

          <label for="mailContentAfterTable">Mailinhalt nach Tabelle</label>
          <p-inputGroup>
            <p-editor formControlName="mailContentAfterTable" [style]="{ height: '320px' }" />
          </p-inputGroup>
        </div>
      </p-tabPanel>
    </p-tabView>

    <div class="submit pt-5">
      <p-button label="Speichern" (click)="onSaveClick()"></p-button>
      <input [style.display]="'none'" type="submit" />
    </div>
  </form>
}
