<div class="form-error mt-1">
  {{ fieldName }}:
  @if (type === 'pattern') {
    has invalid pattern
  } @else if (type === 'empty') {
    cannot be empty
  } @else if (type === 'invalid') {
    is invalid
  } @else {
    has errored
  }
</div>
